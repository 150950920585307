import * as React from "react"
import Layout from "../layouts/default"
// import { Link } from "gatsby"

// styles
const centerCenter = {
  display: "flex",
  flexDirection: "vertical",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh"
}

// markup
export default function NotFoundPage() {
  return (
    <Layout
      title="Not found"
    >
      <div style={centerCenter}>
        <center>
          <h4>404<br />Page Not Found</h4>
        </center>
      </div>
    </Layout>
  )
}
